import React, { useState, useEffect } from "react";

import carApi from "api/carApi";
import {
  Button,
  DatePicker,
  Image,
  Input,
  Modal,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { ShoppingOutlined, FilePdfOutlined } from "@ant-design/icons";
// import { useMediaQuery } from "@mui/material";
import { currencyFormat, currencyFormatTH } from "components/utils/Utils";
import Loading from "components/Loading";

import moment from "moment/moment";
import "moment/locale/th";
import { Link, useNavigate } from "react-router-dom";
import AuctionUserListModal from "./AuctionUserListModal";
import EditProductStatus from "./EditProductStatus";
import { useDispatch, useSelector } from "react-redux";
import { addToken } from "components/store/tokenSlice";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { offerPricePDF } from "./OfferPricePDF";
import SelectCompanyModal from "./SelectCompanyModal";

const { Search } = Input;

pdfMake.fonts = {
  THSarabunNew: {
    normal: "THSarabunNew.ttf",
    bold: "THSarabunNew Bold.ttf",
    italics: "THSarabunNew Italic.ttf",
    bolditalics: "THSarabunNew BoldItalic.ttf",
  },
  AngsanaNew: {
    normal: "AngsanaNew.ttf",
    bold: "AngsanaNew Bold.ttf",
    italics: "AngsanaNew Italic.ttf",
    bolditalics: "AngsanaNew Bold Italic.ttf",
  },
  Roboto: {
    normal: "Roboto-Regular.ttf",
    bold: "Roboto-Medium.ttf",
    italics: "Roboto-Italic.ttf",
    bolditalics: "Roboto-MediumItalic.ttf",
  },
};

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function BookProductList() {
  const user = useSelector((state) => state.token);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const theme = useTheme();
  // const isMediumUp = useMediaQuery(theme.breakpoints.up("md"));
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [auctionUserList, setAuctionUserList] = useState([]);
  const [dataEdit, setDataEdit] = useState({});
  const [openAuctionListModal, setOpenAuctionListModal] = useState(false);
  const [openProductStatusModal, setOpenProductStatusModal] = useState(false);
  const [type, setType] = useState(1);
  const [openSelectCompanyModal, setOpenSelectCompanyModal] = useState(false);

  useEffect(() => {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
  }, []);

  const columns = [
    // isMediumUp
    {
      key: 1,
      // dataIndex: "image_path",
      title: "รูปภาพ",
      render: (rec) => {
        return (
          <Space direction="vertical" align="center">
            <Image width={200} src={rec.image_path} />
            <Typography>เลขสัญญาเดิม : {rec.contract_no}</Typography>
            <Typography>{rec.register_no}</Typography>
          </Space>
        );
      },
    },
    // : {
    //     key: 0,
    //   },

    {
      key: 2,
      // dataIndex: "user_id",
      title: "รายละเอียดรถ",
      render: (rec) => {
        return (
          <div style={{ flexDirection: "column", display: "flex" }}>
            <Link to={"/products/" + rec.id}>
              {"รหัสสินค้า : " + rec.product_code}
            </Link>
            <Typography style={{ fontWeight: "bold" }}>
              ({rec.year}) {rec.brand}
            </Typography>
            <Typography style={{ color: "gray" }}>{rec.model}</Typography>
            <Typography style={{ color: "gray" }}>
              เลขเครื่อง : {rec.chaiss_no}
            </Typography>
            <Typography style={{ color: "gray" }}>
              เลขไมล์ : {currencyFormat(rec.miles)}
            </Typography>
            <Typography style={{ fontWeight: "bold" }}>
              ราคาเปิดประมูล : {currencyFormat(rec.auction_price)}
            </Typography>
          </div>
        );
      },
    },
    {
      key: 3,
      title: "ยอดประมูล",
      render: (rec) => {
        return (
          <div style={{ flexDirection: "column", display: "flex" }}>
            {rec.auction_total !== 0 ? (
              <>
                <Typography style={{ fontWeight: "bold" }}>
                  {"ยอดปิด : " + currencyFormat(rec.auction_total)}
                </Typography>
                <Typography style={{ color: "gray" }}>
                  {"ประมูลล่าสุด : " +
                    moment(rec.auction_total_update_datetime).format("lll")}
                </Typography>
              </>
            ) : (
              <Typography style={{ fontWeight: "bold", color: "red" }}>
                ไม่มีการประมูล
              </Typography>
            )}
            <Typography style={{ color: "gray" }}>
              {"ปิดประมูล : " + moment(rec.auction_due_datetime).format("lll")}
            </Typography>
          </div>
        );
      },
    },
    {
      key: 4,
      // dataIndex: "name",
      title: "ผู้ชนะประมูล",
      render: (rec) => {
        return rec.process_by_user_id ? (
          <div
            style={{
              flexDirection: "column",
              display: "flex",
              justifyContent: "start",
            }}
          >
            <Link to={"/users/" + rec.user_id}>
              {rec.user_car_tent_name ||
                rec.user_name +
                  " " +
                  rec.user_lastname +
                  " (" +
                  rec.user_nickname +
                  ")"}
            </Link>

            {/* <Typography>อายุ : {rec.user_age}</Typography> */}
            <Typography style={{ color: "gray" }}>
              ที่อยู่ : {rec.user_car_tent_address}
            </Typography>
            <Typography style={{ color: "gray" }}>
              โทรศัพท์ : {rec.user_phone}
            </Typography>
            <Link
              style={{ marginTop: 16 }}
              onClick={() => fetchAuctionUserList(rec.id)}
            >
              ดูผู้ร่วมประมูลทั้งหมด
            </Link>
          </div>
        ) : (
          <Typography style={{ fontWeight: "bold", color: "red" }}>
            ไม่พบข้อมูลผู้ประมูล
          </Typography>
        );
      },
    },
    {
      key: 5,
      title: "สถานะ",
      render: (rec) => {
        let color;
        switch (rec.status_type_id) {
          case 1:
            color = "success";
            break;
          case 2:
            color = "processing";
            break;
          case 3:
            color = "error";
            break;
          case 4:
            color = "error";
            break;
          default:
            break;
        }
        return (
          <Space direction="vertical">
            <Tag color={color}>{rec.status}</Tag>
            {rec.status_type_id === 3 ||
              (rec.status_type_id === 4 && (
                <Tag color="success">
                  {currencyFormatTH(rec.sold_out_price)}
                </Tag>
              ))}
          </Space>
        );
      },
    },
    {
      key: 6,
      title: "ตัวเลือก",
      render: function (rec) {
        return (
          <Space align="center">
            <Tooltip title="แก้ไขสถานะสินค้า">
              <Button
                type="primary"
                size="large"
                shape="circle"
                icon={<ShoppingOutlined />}
                onClick={() => {
                  handleClickEditStatus(rec);
                }}
              />
            </Tooltip>
            <Tooltip title="พิมพ์ใบขอซื้อ">
              <Button
                size="large"
                shape="circle"
                icon={<FilePdfOutlined />}
                onClick={() => handleClickPrintOfferPrice(rec)}
              />
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  const fetchAuctionUserList = async (id) => {
    setLoading(true);
    await carApi
      .get(`/salecar/admin/auction-log/product/${id}`, {
        headers: {
          "x-access-token": user.token,
        },
      })
      .then((response) => {
        // console.log(response.data);
        setAuctionUserList(response.data);
        setOpenAuctionListModal(true);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        dispatch(addToken({}));
        navigate("/login");
        Modal.warning({
          title: "คำขอล้มเหลว",
          content: "กรุณาเข้าสู่ระบบ",
          centered: true,
        });
      });
  };

  useEffect(() => {
    fetchAllProduct();
  }, []);

  const fetchAllProduct = async () => {
    setLoading(true);
    await carApi
      .get("/salecar/admin/auctions-completed", {
        headers: {
          "x-access-token": user.token,
        },
      })
      .then((res) => {
        const data = res.data;
        // console.log("Products", data);
        setData(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        dispatch(addToken({}));
        navigate("/login");
        Modal.warning({
          title: "คำขอล้มเหลว",
          content: "กรุณาเข้าสู่ระบบ",
          centered: true,
        });
      });
  };

  const fetchByRegisterNumber = async (value) => {
    let typeUrl = "";
    if (type === 1) {
      typeUrl = "register";
    } else {
      typeUrl = "chassis";
    }
    console.log(typeUrl);
    setLoading(true);
    await carApi
      .get("/salecar/admin/search/" + typeUrl, {
        params: { [typeUrl]: value },
        headers: {
          "x-access-token": user.token,
        },
      })
      .then((res) => {
        const data = res.data;
        // console.log("Products", data);
        setData(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        dispatch(addToken({}));
        navigate("/login");
        Modal.warning({
          title: "คำขอล้มเหลว",
          content: "กรุณาเข้าสู่ระบบ",
          centered: true,
        });
      });
  };

  const updateProductStatus = async (data) => {
    // console.log("UPDATE STATUS", data);
    setLoading(true);
    await carApi
      .put("/salecar/admin/products/" + data.id + "/status", data, {
        headers: {
          "x-access-token": user.token,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          Modal.success({ content: "อัพเดพข้อมูลแล้ว", centered: true });
          fetchAllProduct();
        } else {
          setLoading(false);
          Modal.error({
            title: "ผิดพลาด",
            content: "อัพเดตไม่สำเร็จ กรุณาตรวจสอบข้อมูล",
            centered: true,
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        dispatch(addToken({}));
        navigate("/login");
        Modal.warning({
          title: "คำขอล้มเหลว",
          content: "กรุณาเข้าสู่ระบบ",
          centered: true,
        });
      });
  };

  const fetchAuctionListByDate = async (date) => {
    setLoading(true);
    await carApi
      .get("/salecar/admin/auctions-completed/" + date, {
        headers: {
          "x-access-token": user.token,
        },
      })
      .then((res) => {
        const data = res.data;
        // console.log("Products", data);
        setData(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        dispatch(addToken({}));
        navigate("/login");
        Modal.warning({
          title: "คำขอล้มเหลว",
          content: "กรุณาเข้าสู่ระบบ",
          centered: true,
        });
      });
  };

  const handleClickEditStatus = (data) => {
    // console.log("EditData", data);
    setDataEdit(data);
    setOpenProductStatusModal(true);
  };

  const handleSubmit = (data) => {
    console.log("Submit", data);
    updateProductStatus(data);
  };

  const onChangeMonth = (date, dateString) => {
    if (dateString !== "") {
      fetchAuctionListByDate(dateString);
    }
  };

  const handleChangeSearchType = (data) => {
    console.log("TYPE", data);
    setType(data);
  };

  const handleSearch = (value) => {
    console.log("VALUE", value);
    if (value) {
      fetchByRegisterNumber(value);
    } else {
      fetchAllProduct();
    }
  };

  const handleClickPrintOfferPrice = async (rec) => {
    setDataEdit(rec);
    setOpenSelectCompanyModal(true);
  };

  const handleSubmitSelectCompany = async (data) => {
    console.log("DATA SUBMIT", data);
    const docDefinition = await offerPricePDF(data);
    pdfMake.createPdf(docDefinition).open();
  };

  return (
    <>
      <h3>รายการปิดประมูล</h3>
      <Space>
        <p>กรองตามเดือน</p>
        <DatePicker
          onChange={onChangeMonth}
          picker="month"
          placeholder="เดือนที่ปิดประมูล"
          style={{ width: 200 }}
        />
      </Space>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Space>
          <p>ค้นหาจาก</p>
          <Select
            style={{
              width: 150,
            }}
            options={[
              { value: 1, label: <span>เลขทะเบียน</span> },
              { value: 2, label: <span>เลข Chassis</span> },
            ]}
            onChange={handleChangeSearchType}
          />
          <Search
            placeholder="กรอกเลขทะเบียนหรือเลขคัตซี"
            allowClear
            onSearch={handleSearch}
            style={{
              width: 200,
            }}
          />
        </Space>
      </div>
      {loading ? (
        <Loading />
      ) : (
        <Table dataSource={data} columns={columns} rowKey={"id"} />
      )}

      <AuctionUserListModal
        open={openAuctionListModal}
        data={auctionUserList}
        // onSubmit={updateAuctionData}
        onClose={setOpenAuctionListModal}
      />
      <EditProductStatus
        open={openProductStatusModal}
        data={dataEdit}
        onSubmit={handleSubmit}
        onClose={setOpenProductStatusModal}
      />
      <SelectCompanyModal
        open={openSelectCompanyModal}
        data={dataEdit}
        onClose={setOpenSelectCompanyModal}
        onSubmit={handleSubmitSelectCompany}
      />
    </>
    // <PDFViewer>
    //   <MyDocument />
    // </PDFViewer>
  );
}
